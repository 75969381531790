// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
import { GA_TRACKING_ID } from '../constants';
import { isProduction } from './helpers';

type Event = {
  action: string;
  category?: string;
  label: string;
  value: string;
};

const pageView = (url: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
const event = ({ action, category, label, value }: Event) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const ga = {
  pageView,
  event,
};

// type some

type Type = {
  event: string;
  [key: string]: string | number;
};

export const analyticsDataLayerPush = ({ event, ...rest }: Type) => {
  if (isProduction) {
    window.dataLayer.push({ event, ...rest });
  }
};
